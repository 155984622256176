<template>
  <div id="login" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="logo-moonstake">
      <img v-if="themeName === 'moonstake'" src="@/assets/images/themes/moonstake/logo_horizontal_black.svg" />
      <img v-if="themeName === 'binarystar'" src="@/assets/images/themes/binarystar/binarystar-white-logo.svg" />
    </div>
    <div class="expired-tooltip" v-if="isExpired == true && isShowErrorExpired == true">
      <p>
        {{ $t('LOGIN_FORM_COMPONENT_TEXT_EXPIRED_TOOLTIP') }}
        <i class="fas fa-times" @click="isShowErrorExpired = false"></i>
      </p>
    </div>
    <CRow class="box-container">
      <CCol md="12">
        <CCard class="p-4">
          <CCardBody class="custom-card-body">
            <ValidationObserver ref="observer" @submit.prevent="actionHandler">
              <CForm>
                <div v-if="formType === 'FORGOT_PASSWORD'" class="return-login">
                  <a @click="toLogin()"> <CIcon :content="cilArrowLeft" />{{ $t('LOGIN_FORM_COMPONENT_BUTTON_BACK_TO_LOGIN') }} </a>
                </div>
                <h1 class="mb-3">{{ formTitle }}</h1>
                <div v-if="formType === 'FORGOT_PASSWORD'" class="forgot-des">{{ textDont }}</div>
                <p v-if="formType === 'LOGIN'" class="text-muted">{{ $t('LOGIN_FORM_COMPONENT_TITLE_LOGIN') }}</p>

                <div v-if="label1">
                  <ValidationProvider :name="label1" :rules="getRules(true)" v-slot="{ errors }" vid="password">
                    <CInput
                      :class="formType === 'SET_PASSWORD' || formType === 'ACTIVE_USER' ? 'input1 input--w' : 'input1'"
                      v-model="input1"
                      :type="
                        (formType === 'RESET_PASSWORD' && isShowPass === false) ||
                        formType === 'ACTIVE_USER' ||
                        (formType === 'SET_PASSWORD' && isShowPass === false)
                          ? 'password'
                          : 'text'
                      "
                      :label="label1"
                      autocomplete="username email"
                      :placeholder="text6"
                    >
                      <template #prepend v-if="formType === 'SET_PASSWORD'">
                        <i v-if="isShowPass === false" class="far fa-eye-slash" @click="isShowPass = !isShowPass"></i>
                        <i v-else class="far fa-eye" @click="isShowPass = !isShowPass"></i>
                      </template>
                    </CInput>
                    <CRow class="row-err">
                      <CCol class="error-msg-wrap">
                        <p class="error-msg">{{ errors[0] }}</p>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                </div>

                <div v-if="label2">
                  <ValidationProvider :name="label2" :rules="getRules()" v-slot="{ errors }">
                    <CInput
                      :class="formType === 'SET_PASSWORD' ? 'input2 input--w dn-ico' : 'input2'"
                      v-model="input2"
                      :label="label2"
                      :type="isShowPass === true ? 'text' : 'password'"
                      autocomplete="curent-password"
                      :placeholder="text7"
                    >
                      <template #prepend>
                        <i v-if="isShowPass === false" class="far fa-eye-slash" @click="isShowPass = !isShowPass"></i>
                        <i v-else class="far fa-eye" @click="isShowPass = !isShowPass"></i>
                      </template>
                    </CInput>
                    <CRow class="row-err">
                      <CCol class="error-msg-wrap">
                        <p class="error-msg">{{ errors[0] }}</p>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                </div>

                <div v-if="formType === 'LINK_EXPIRED_USER'">
                  <p>
                    {{ $t('LOGIN_FORM_COMPONENT_TEXT_LINK_EXPIRED_USER') }}
                    <br />{{ $t('LOGIN_FORM_COMPONENT_TEXT_REQUEST_NEW_ONE') }}
                  </p>
                </div>

                <div v-if="formType === 'EXPIRED_CONFIRM_REQUEST_LINK'">
                  <p>
                    {{ $t('LOGIN_FORM_COMPONENT_TEXT_LINK_EXPIRED_USER') }}
                    <br />{{ $t('LOGIN_FORM_COMPONENT_TEXT_REQUEST_NEW_ONE') }}
                  </p>
                </div>

                <div v-if="formType === 'EXPIRED_CONFIRM_IP_LINK'">
                  <p>
                    {{ $t('LOGIN_FORM_COMPONENT_TEXT_LINK_EXPIRED_USER') }}
                    <br />{{ $t('LOGIN_FORM_COMPONENT_TEXT_REQUEST_NEW_ONE') }}
                  </p>
                </div>

                <div v-if="formType === 'EXPIRED_APPROVE_REQUEST_LINK'">
                  <p>
                    {{ $t('LOGIN_FORM_COMPONENT_TEXT_LINK_EXPIRED_USER') }}
                    <br />{{ $t('LOGIN_FORM_COMPONENT_TEXT_REQUEST_NEW_ONE') }}
                  </p>
                </div>

                <div v-if="formType === 'CONFIRM_NEW_IP'" class="text-center">
                  <p>{{ $t('LOGIN_FORM_COMPONENT_TEXT_PROCESSING') }}</p>
                </div>

                <div v-if="formType === 'CONFIRM_REQUEST'" class="text-center">
                  <p>{{ $t('LOGIN_FORM_COMPONENT_TEXT_PROCESSING') }}</p>
                </div>

                <div v-if="formType === 'APPROVE_REQUEST'" class="text-center">
                  <p>{{ $t('LOGIN_FORM_COMPONENT_TEXT_PROCESSING') }}</p>
                </div>

                <div v-if="formType === 'RESET_PASSWORD' || formType === 'SET_PASSWORD'">
                  <div class="w-49 mg-112">
                    <ValidationProvider :rules="getMinPass()">
                      <div :class="className1">
                        <CIcon class="icon-config mr-1 mg-3" :class="classCheck1" :content="tick1" />
                        {{ text1 }}
                      </div>
                    </ValidationProvider>
                    <ValidationProvider :rules="getLowerPass()">
                      <div :class="className2">
                        <CIcon class="icon-config mr-1" :class="classCheck2" :content="tick2" />
                        {{ text2 }}
                      </div>
                    </ValidationProvider>
                    <ValidationProvider :rules="getUpperPass()">
                      <div :class="className3">
                        <CIcon class="icon-config mr-1 mg-3" :class="classCheck3" :content="tick3" />
                        {{ text3 }}
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="w-49 mg-49">
                    <ValidationProvider :rules="getNumberPass()">
                      <div :class="className4">
                        <CIcon class="icon-config mr-1 mg-3" :class="classCheck4" :content="tick4" />
                        {{ text4 }}
                      </div>
                    </ValidationProvider>
                    <ValidationProvider :rules="getSpecialPass()">
                      <div :class="className5">
                        <CIcon class="icon-config mr-1" :class="classCheck5" :content="tick5" />
                        {{ text5 }}
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <CRow v-if="formType === 'FORGOT_PASSWORD' || formType === 'SET_PASSWORD'" class="justify-content-center mt-4">
                  <CButton type="submit" class="btn-fogot px-4">{{ btnLabel }}</CButton>
                </CRow>
                <CRow
                  v-if="
                    formType !== 'FORGOT_PASSWORD' &&
                    formType !== 'SET_PASSWORD' &&
                    formType !== 'LINK_EXPIRED_USER' &&
                    formType !== 'EXPIRED_CONFIRM_IP_LINK' &&
                    formType !== 'CONFIRM_NEW_IP' &&
                    formType !== 'CONFIRM_REQUEST' &&
                    formType !== 'EXPIRED_CONFIRM_REQUEST_LINK' &&
                    formType !== 'EXPIRED_APPROVE_REQUEST_LINK' &&
                    formType !== 'APPROVE_REQUEST'
                  "
                  class="justify-content-center mt-4"
                >
                  <CButton type="submit" class="btn-login px-4">{{ btnLabel }}</CButton>
                </CRow>
                <CCol :class="formType === 'FORGOT_PASSWORD' ? 'invisible' : ''" class="forgot-text" cols="12">
                  <CButton
                    v-if="
                      formType !== 'SET_PASSWORD' &&
                      formType !== 'CONFIRM_NEW_IP' &&
                      formType !== 'EXPIRED_CONFIRM_IP_LINK' &&
                      formType !== 'EXPIRED_CONFIRM_REQUEST_LINK' &&
                      formType !== 'CONFIRM_REQUEST' &&
                      formType !== 'EXPIRED_APPROVE_REQUEST_LINK' &&
                      formType !== 'APPROVE_REQUEST'
                    "
                    @click="clickForgotPassword"
                    color="link"
                    >{{ $t('LOGIN_FORM_COMPONENT_BUTTON_FORGET_PASSWORD') }} ?</CButton
                  >
                </CCol>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { cilX, cilArrowLeft, cilCheckAlt } from '@coreui/icons';
import endpoints from '../constants/endpoints';
import { env } from '@/constants';
import SetPassword from '../views/pages/SetPassword';

export default {
  props: {
    formTitle: {
      type: String,
      default: '',
    },
    textDont: {
      type: String,
      default: '',
    },
    formType: {
      type: String,
      default: '',
    },
    btnLabel: {
      type: String,
      default: 'Ok',
    },
    label1: {
      type: String,
      default: undefined,
    },
    label2: {
      type: String,
      default: undefined,
    },
    label3: {
      type: String,
      default: undefined,
    },
    rulesRequired: {
      type: Boolean,
      default: false,
    },
    rulesEmail: {
      type: Boolean,
      default: false,
    },
    rulesMinPassword: {
      type: Boolean,
      default: false,
    },
    rulesConfirmed: {
      type: Boolean,
      default: false,
    },
    siteKey: {
      type: String,
      default: '',
    },
    text1: {
      type: String,
      default: '',
    },
    text2: {
      type: String,
      default: '',
    },
    text3: {
      type: String,
      default: '',
    },
    text4: {
      type: String,

      default: '',
    },
    text5: {
      type: String,
      default: '',
    },
    text6: {
      type: String,
      default: '',
    },
    text7: {
      type: String,
      default: '',
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      themeName: env.theme.name,
      input1: '',
      input2: '',
      isShowPass: false,
      isShowErrorExpired: true,
      captchaToken: '',
      cilX,
      cilArrowLeft,
      cilCheckAlt,
      className1: {
        type: String,
        default: '',
      },
      classCheck1: {
        type: String,
        default: '',
      },
      tick1: {
        type: Object,
        default: function () {
          return cilX;
        },
      },
      className2: {
        type: String,
        default: '',
      },
      classCheck2: {
        type: String,
        default: '',
      },
      tick2: {
        type: Object,
        default: function () {
          return cilX;
        },
      },
      className3: {
        type: String,
        default: '',
      },
      classCheck3: {
        type: String,
        default: '',
      },
      tick3: {
        type: Object,
        default: function () {
          return cilX;
        },
      },
      className4: {
        type: String,
        default: '',
      },
      classCheck4: {
        type: String,
        default: '',
      },
      tick4: {
        type: Object,
        default: function () {
          return cilX;
        },
      },
      className5: {
        type: String,
        default: '',
      },
      classCheck5: {
        type: String,
        default: '',
      },
      tick5: {
        type: Object,
        default: function () {
          return cilX;
        },
      },
    };
  },
  async created() {
    await this.$recaptchaLoaded();
  },
  methods: {
    captchaExpired() {
      this.captchaToken = '';
    },
    getCaptcha(token) {
      this.captchaToken = token;
    },
    async resetCaptcha() {
      this.captchaToken = await this.$recaptcha('login');
      const recaptcha = this.$recaptchaInstance;

      // this.$refs.grecaptcha.reset();
    },
    getMinPass() {
      const re1 = this.input1;
      const re2 = /.{10,}/;
      if (re2.test(re1) === true) {
        (this.className1 = 'passed'), (this.classCheck1 = 'check');
        this.tick1 = cilCheckAlt;
      } else {
        (this.className1 = ''), (this.classCheck1 = '');
        this.tick1 = cilX;
      }
    },
    getLowerPass() {
      const re1 = this.input1;
      const re2 = /[a-z]+/;
      if (re2.test(re1) === true) {
        (this.className2 = 'passed'), (this.classCheck2 = 'check');
        this.tick2 = cilCheckAlt;
      } else {
        (this.className2 = ''), (this.classCheck2 = '');
        this.tick2 = cilX;
      }
    },
    getUpperPass() {
      const re1 = this.input1;
      const re2 = /[A-Z]+/;
      if (re2.test(re1) === true) {
        (this.className3 = 'passed'), (this.classCheck3 = 'check');
        this.tick3 = cilCheckAlt;
      } else {
        (this.className3 = ''), (this.classCheck3 = '');
        this.tick3 = cilX;
      }
    },
    getNumberPass() {
      const re1 = this.input1;
      const re2 = /[0-9]/;
      if (re2.test(re1) === true) {
        (this.className4 = 'passed'), (this.classCheck4 = 'check');
        this.tick4 = cilCheckAlt;
      } else {
        (this.className4 = ''), (this.classCheck4 = '');
        this.tick4 = cilX;
      }
    },
    getSpecialPass() {
      const re1 = this.input1;
      // const re2 = /[-!$%^@&*()_+|~=`{}\[\]:";'<>?,.\/]/;
      const re2 = /[-!$%^@&*()_+|~=`{}[\]:";'<>?,./]/;
      if (re2.test(re1) === true) {
        (this.className5 = 'passed'), (this.classCheck5 = 'check');
        this.tick5 = cilCheckAlt;
      } else {
        (this.className5 = ''), (this.classCheck5 = '');
        this.tick5 = cilX;
      }
    },
    getRules(fieldPassword = false) {
      // for set-new-password
      if (fieldPassword) {
        if (this.rulesRequired && this.rulesEmail) {
          return 'required|email';
        }

        if (this.rulesRequired && this.rulesMinPassword) {
          return {
            required: true,
            min: 6,
            max: 30,
            regex: /^[a-zA-Z0-9!@#$%^&*()-_=+]{6,30}$/,
          };
        }

        if (this.rulesRequired) {
          return 'required';
        }
      }

      // form confirm
      if (this.rulesRequired && this.rulesConfirmed) {
        return 'required|confirmed:password';
      }

      // form login
      if (this.rulesRequired) {
        return {
          required: true,
          min: 6,
          max: 30,
          regex: /^[a-zA-Z0-9!@#$%^&*()-_=+]{6,30}$/,
        };
      }
    },
    async actionHandler() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      this.captchaToken = await this.$recaptcha('login');

      if (this.formType === 'LOGIN') {
        const data = {
          email: this.input1,
          password: this.input2,
          captchaToken: this.captchaToken,
        };
        this.$emit('login', data);
      }

      if (this.formType === 'FORGOT_PASSWORD') {
        const data = {
          email: this.input1,
          captchaToken: this.captchaToken,
        };
        this.$emit('send', data);
      }

      if (this.formType === 'SET_PASSWORD' || this.formType === 'RESET_PASSWORD' || this.formType === 'ACTIVE_USER') {
        if (this.input1 !== this.input2) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Error',
            text: 'Password and confirm password does not match',
          });
          return;
        }

        const data = { password: this.input1 };
        this.$emit('update', data);
      }
    },
    clickForgotPassword() {
      this.$router.push('/forgot-password');
    },
    toLogin() {
      this.$store.state.platform.checkExpired = false;
      this.$router.push('/login');
    },
  },
  components: {},
  name: 'LoginFormComponent',
};
</script>

<style lang="scss">
#login {
  flex-direction: column;
  .logo-moonstake {
    margin-bottom: 40px;
    img {
      @media (max-width: 767px) {
        width: 250px;
      }
    }
  }
  .box-container {
    width: 600px;
    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    .p-4 {
      padding: 1.5rem 15px !important;
    }
    .custom-card-body {
      @media (min-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        padding: 0;
      }
      .captcha {
        margin-top: 15px;
        padding-left: 105px;
        @media (max-width: 700px) {
          order: 2;
          padding-left: 15px;
        }
        div {
          width: 100% !important;
          @media (max-width: 700px) {
            text-align: center;
          }
        }
      }
      .forgot-text {
        margin-top: 3px;
        text-align: center;
        color: #695fe5;
        font-size: 14px;
        line-height: 17px;
        @media (max-width: 700px) {
          text-align: right;
          margin-right: -10px;
          margin-bottom: -10px;
          color: #3c4b64;
          font-size: 35px;
          line-height: 42px;
        }
      }
      .forgot-des {
        padding-bottom: 18px;
        color: #3e4b65;
        font-size: 14px;
        line-height: 22px;
      }

      .input1,
      .input2 {
        color: #3e4b65;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
        label {
          width: 90px;
          text-align: right;
          padding-right: 15px;
          line-height: 35px;
          margin: 0;
        }
        input {
          width: calc(100% - 90px);
          box-sizing: border-box;
          height: 35px;
          border: 1px solid #d7dbe0;
          border-radius: 4px;
          background-color: none;
          &:focus {
            box-shadow: none !important;
          }
        }
        .input-group {
          width: calc(100% - 90px);
          input {
            width: 100%;
          }
        }
      }
      .input1,
      .input2 {
        &.dn-ico {
          .input-group-prepend {
            display: none;
          }
        }
        &.input--w {
          label {
            width: 145px;
            margin: 0;
            padding: 0;
            line-height: 35px;
            text-align: right;
          }
          .input-group {
            width: calc(100% - 145px);
            @media (max-width: 1024px) {
              width: 74%;
            }
            @media (max-width: 767px) {
              width: calc(100% - 145px);
            }
          }
        }
        .input-group-prepend {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 10px;
          bottom: 0;
          align-items: center;
          z-index: 99;
          i {
            font-size: 18px;
          }
        }
      }
      .error-msg {
        color: red !important;
        text-align: left;
        padding-left: 90px;
        margin: 6px 0 0 0 !important;
      }
    }
    .text-muted {
      margin-bottom: 10px;
    }

    .form-group {
      margin-bottom: 0;
    }

    input {
      @media (min-width: 1100px) {
        width: 300px;
      }
    }
    .input1 {
      margin-top: 41px;
    }
    .input2 {
      margin-bottom: 0;
      margin-top: 27px;
      input {
        border: none !important;
        border-radius: 0 !important;
        width: 92% !important;
        flex: inherit !important;
        &:focus {
          box-shadow: none !important;
        }
      }
      .input-group {
        border: 1px solid #d7dbe0;
        border-radius: 4px;
        &:focus {
          box-shadow: 0 0 5px #dadada !important;
        }
      }
    }
    .btn-login {
      color: white;
      height: 35px;
      width: 94%;
      border-radius: 4px;
      background-color: #3b4b7e;
    }
    .btn-fogot {
      @extend .btn-login;
      width: 50%;
    }
    .w-49 {
      display: inline-block;
      vertical-align: top;
      margin-top: 10px;
      margin-bottom: 12px;
      .passed {
        color: #b4b4b4;
      }
      div {
        margin-bottom: 7px;
        color: #3e4b65;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
      }
      .check {
        color: #7ed321;
      }
      .mg-3 {
        margin-top: -3px;
      }
      .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
    .mg-112 {
      margin-left: 112px;
    }
    .mg-49 {
      margin-left: 49px;
    }
  }
  .mt-8 {
    margin-top: 8px;
  }
  .return-login {
    a {
      cursor: pointer;
      color: #695fe5;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
      .c-icon {
        height: 14px;
        margin-top: -3px;
        margin-right: 3px;
      }
    }
    margin-top: 16px;
    padding-bottom: 20px;
    text-align: left;
  }
  .expired-tooltip {
    position: relative;
    text-align: center;
    padding: 0 30px 0 15px;
    border: 1px solid #f8cfcf;
    border-radius: 5px;
    background-color: #fadddd;
    margin-bottom: 10px;
    p {
      color: #772b35;
      /* font-family: "SF UI Display"; */
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
      padding: 18.5px 0;
      margin: 0;
      i {
        position: absolute;
        font-size: 14px;
        top: 21px;
        right: 10px;
      }
    }
  }
}
</style>
