<template>
  <CContainer id="setpassword">
    <LoginFormComponent
      formType="SET_PASSWORD"
      formTitle="Reset password"
      btnLabel="Submit"
      label1="New password"
      label2="Confirm password"
      text1="10 characters minimum"
      text2="One lowercase character"
      text3="One uppercase character"
      text4="One number"
      text5="One special character"
      text6="Enter your new password"
      @update="update"
      :rulesRequired="true"
      :rulesMinPassword="true"
      :rulesConfirmed="true"
    />
  </CContainer>
</template>

<script>
import _ from 'lodash';
import LoginFormComponent from '@/components/LoginFormComponent';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'SetPassword',
  data() {
    return {
      isSetPass: false,
    };
  },
  components: { LoginFormComponent },
  async mounted() {
    const token = _.trim(this.$route.query.token);
    this.token = token;

    const resultTokens = await this.$http.get(endpoints.checkToken(this.token));
    if (resultTokens.data.token_sts !== 'VALID') {
      // this.$notify({
      //   group: 'notifications',
      //   type: 'expired',
      //   text: 'You have tried to use a reset password link that has expired. Try again.',
      //   duration: -1,
      // })
      this.$store.state.platform.checkExpired = true;
      this.$router.push('/forgot-password');
    }
  },
  methods: {
    async update({ password }) {
      const re1 = /^[a-zA-Z0-9!@#$%^&*()-_=+]{10,30}$/;
      const re2 = re1.test(password);

      if (!re2) {
        return this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: 'Password rule does not satisfy',
        });
      }

      try {
        const token = this.token;
        const params = {
          verify_token: token,
          password: password,
        };
        const result = await this.$http.post(endpoints.setPassword, params);

        if (result) {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Success',
            text: 'Set new password success!',
          });
          this.toLogin();
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
        this.$router.push('/forgot-password');
      }
    },
    toLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang='scss' scoped>
#setpassword /deep/ {
  .card-body {
    h1 {
      margin-bottom: 0 !important;
    }
    .input1 label {
      margin-left: 0;
    }
  }
}
</style>
