import { render, staticRenderFns } from "./SetPassword.vue?vue&type=template&id=40b3a79a&scoped=true&"
import script from "./SetPassword.vue?vue&type=script&lang=js&"
export * from "./SetPassword.vue?vue&type=script&lang=js&"
import style0 from "./SetPassword.vue?vue&type=style&index=0&id=40b3a79a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b3a79a",
  null
  
)

export default component.exports